<template>
  <div class="content-wrapper">
    <div class="employee main-content">
      <div class="page-header">
        <h1>Payment</h1>
      </div>
      <PaymentForm :viewMode="true" :eventName="'updatePayment'" @updatePayment="updatePayment" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import PaymentForm from '@/components/financial/PaymentForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Financial from '@/lib/financial';

export default {
  name: 'Payment',
  components: {
    PaymentForm,
  },
  mounted(){
    this.loadPayment();
  },
  methods:{
    async loadPayment(){
      try{
        const loginInfo = Common.getLoginInfo();
        const paymentId = this.$route.params.id;
        const payment = await Financial.loadPayment(this.apiUrl, paymentId, loginInfo);
        this.setCurrentPayment(payment);
      }catch(err){
        console.log(err);
      }
    },
    async updatePayment(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const paymentId = this.$route.params.id;
        const paymentInfo = Object.assign({}, form);
        
        const employee = await this.getEmployeeByName(form.employeeId);
        const employeeId = employee.id;
        paymentInfo.employeeId = employeeId;

        const payment = await Financial.updatePayment(this.apiUrl, paymentId, paymentInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    ...mapActions(['setCurrentPayment']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
</style>
